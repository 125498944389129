html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}
a {
  text-decoration: none;
}
a:visited {
  color: inherit;
}
div#root {
  height: 100%;
}
